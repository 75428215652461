import React from 'react'
import { PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import ProjectView from '../views/project.view.js'

const ProjectPage: React.FC<PageProps> = () => {
  const { t } = useTranslation('custom')
  return (
    <Layout seo={{ webflow: '5fea46229870964cc9ba6397', title: t('Votre Projet') }}>
      <ProjectView />
    </Layout>
  )
}

export default ProjectPage
