
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import georgetSite from "../images/georget-site.jpg"
import georgetSiteP500 from "../images/georget-site-p-500.jpeg"
import georgetSiteP800 from "../images/georget-site-p-800.jpeg"
import georgetSiteP1080 from "../images/georget-site-p-1080.jpeg"
import maillochesSite from "../images/mailloches-site.jpg"
import maillochesSiteP500 from "../images/mailloches-site-p-500.jpeg"
import maillochesSiteP1080 from "../images/mailloches-site-p-1080.jpeg"
import maillochesSiteP1600 from "../images/mailloches-site-p-1600.jpeg"
import icons8Flower250 from "../images/icons8-flower-250.png"
import icons8Asteroid250 from "../images/icons8-asteroid-250.png"
import icons8Index250 from "../images/icons8-index-250.png"
import icons8OnlineShopping250 from "../images/icons8-online-shopping-250.png"
import icons8TechnicalSupport250 from "../images/icons8-technical-support-250.png"
import icons8Template250 from "../images/icons8-template-250.png"
import icons8Infinite250 from "../images/icons8-infinite-250.png"
import shape8 from "../images/shape-8.png"
import shape8P500 from "../images/shape-8-p-500.png"
import shape3 from "../images/shape-3.png"
import shape3P500 from "../images/shape-3-p-500.png"
import shape3P800 from "../images/shape-3-p-800.png"
import shape3P1080 from "../images/shape-3-p-1080.png"
import arrowTop from "../images/arrow-top.png"

  const ProjectView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div id="Team" className="section _50px-top wf-section">
    <div className="project-wrapper _0px-padding">
      <h1 className="heading-3"><Trans>Nos réalisations</Trans></h1>
      <div className="realisations-wrapper">
        <div className="winemaker-presentation">
          <h1 className="realisation-heading"><Trans>Domaine Georget - </Trans><a href="https://domainegeorget.fr/" target="_blank" className="link"><Trans>Voir le site</Trans></a>
          </h1><img src={georgetSite} loading="lazy" sizes="100vw" srcSet={`${georgetSiteP500} 500w, ${georgetSiteP800} 800w, ${georgetSiteP1080} 1080w, ${georgetSite} 2025w`} alt="georgetSite" />
          <p className="paragraph-12"><Trans>Charles Georget est issu d'une famille de vignerons qui produit du vin depuis 1980, son domaine possède des vignes en Agriculture Biologique depuis plus de 40 ans ! Ce qui en fait un des pionniers du vin bio en France.</Trans><br /><br /><Trans>Charles Georget n'avait pas de site marchand, cependant de nombreuses personnes le lui réclamait pour commander ses vins depuis toute la France.&nbsp;Nous avons recueilli ses besoins et lui avons proposé une solution répondant à l'ensemble de ses besoins. </Trans><br /><br /><Trans>Vigneron étant un métier passionnant mais prenant, nécessitant de passer de nombreuses heures à s'occuper de ses vignes. Charles &nbsp;avait donc d'un site qui pouvait se gérer facilement, avec un interface la plus simple possible.</Trans><br /><br /><Trans>Après une demi-journée de prise de besoin nous avons rapidement proposé une maquette à Charles pour qu'il nous confirme son intérêt celle-ci.&nbsp;Puis nous avons avancé de notre côté, et présenter une version définitive du site prenant en compte ses dernières demandes.&nbsp;</Trans><br /><br /><Trans>Aujourd'hui nous sommes fiers de présenter ce site, qui correspond parfaitement à nos attentes en termes de qualité et de performance.</Trans></p>
        </div>
        <div className="winemaker-presentation">
          <h1 className="realisation-heading"><Trans>Domaine des Mailloches - </Trans><a href="https://domainedesmailloches.fr/" target="_blank"><Trans>Voir le site</Trans></a>
          </h1><img src={maillochesSite} loading="lazy" sizes="100vw" srcSet={`${maillochesSiteP500} 500w, ${maillochesSiteP1080} 1080w, ${maillochesSiteP1600} 1600w, ${maillochesSite} 1991w`} alt="maillochesSite" />
          <p><br /><br /><Trans>Samuel Demont est issu d'une famille de vignerons depuis 8&nbsp;générations, ces vins et ceux de ses ancêtres ont été primés de nombreuses fois.&nbsp;Il produit des vins typés Cabernet Franc, très représentatif de la région qui l'a vu grandir.&nbsp;Il reprend le Domaine des Mailloches à la suite de son père en 2002.&nbsp;Depuis lors tous ces vins ont été gratifiés de nombreuses médailles d'Or.</Trans><br /><br /><Trans>Le Domaine des Mailloches possédait un site web âgé de plus d'une quinzaine d'années, il n'était donc plus à jour, difficilement lisible sur mobile, et avait des frais de ports dépassant souvent la valeur du vin vendu.&nbsp;Par ailleurs le client ne pouvait payer que s'il possédait un compte Paypal.</Trans><br /><br /><Trans>Samuel Demont nous à fait confiance, pour refondre entièrement l'identité de son site. En construisant en premier lieu une maquette puis en itérant dessus suivant ses envies, nous sommes arrivés petit à petit au résultat excompté.</Trans><br /><br /><Trans>De plus nous avons aussi repensé l'expérience utilisateur, en réduisant fortement les frais de ports (10 € fixe) et en faisant en sorte que son site s'affiche parfaitement sur tous les appareils mobiles. Enfin le client à la possibilité de régler avec n'importe qu'elle carte bancaire et de manière très sécurisé.</Trans><br /><br /></p>
        </div>
      </div>
    </div>
  </div>
  <div className="catch-phrase-why-us wf-section">
    <h2 className="heading"><Trans>« Ne pas avoir de site web, c'est laissez vos concurrents vendre à votre place »</Trans></h2>
  </div>
  <div className="section gray wf-section">
    <div className="container">
      <div className="display-wrapper">
        <div className="badge yellow"><strong><Trans>Features</Trans></strong><br /></div>
        <h3 className="display-2"><Trans>Nos différences</Trans><br /></h3>
        <div className="top-margin-30px">
          <div className="align-center">
            <div>
              <div className="top-margin-50px">
                <div className="featrues-flex">
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Flower250} loading="lazy" width={30} alt="icons8Flower250" /></div>
                    <p className="paragraph-no-margin"><Trans>Design effervescent </Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Asteroid250} loading="lazy" width={30} alt="icons8Asteroid250" /></div>
                    <p className="paragraph-no-margin"><Trans>Animations détonnantes</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Index250} loading="lazy" width={30} alt="icons8Index250" /></div>
                    <p className="paragraph-no-margin"><Trans>CMS</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8OnlineShopping250} loading="lazy" width={30} alt="icons8OnlineShopping250" /></div>
                    <p className="paragraph-no-margin"><Trans>E-commerce</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8TechnicalSupport250} loading="lazy" width={30} alt="icons8TechnicalSupport250" /></div>
                    <p className="paragraph-no-margin"><Trans>Support souriant</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Template250} loading="lazy" width={30} alt="icons8Template250" /></div>
                    <p className="paragraph-no-margin"><Trans>SEO</Trans></p>
                  </div>
                  <div className="features-wrapper">
                    <div className="green-icon"><img src={icons8Infinite250} loading="lazy" width={30} alt="icons8Infinite250" /></div>
                    <p className="paragraph-no-margin"><Trans>Possibilités infinies</Trans></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="wf-section">
    <div className="container">
      <div className="cta-wrapper">
        <div className="badge white"><Trans>Contact</Trans></div>
        <h4 className="display-2"><Trans>Vous avez un projet ?&nbsp;</Trans><br /><Trans>Parlons-en !</Trans></h4>
        <div className="top-margin-50px">
          <div className="meta-flex center">
            <Link to="/contact" className="primary-button green w-button"><Trans>Nous contacter</Trans></Link>
          </div>
        </div><img src={shape8} loading="lazy" width={120} sizes="(max-width: 479px) 70px, 120px" srcSet={`${shape8P500} 500w, ${shape8} 546w`} alt="shape8" className="scribble-4" /><img src={shape3} loading="lazy" width={60} sizes="(max-width: 479px) 100vw, 60px" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2 left" />
      </div>
    </div>
  </div>
  <a href="#" style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="go-top w-inline-block"><img src={arrowTop} loading="lazy" width={256} alt="arrowTop" className="image" /></a>
</div>)
  };
  
  export default ProjectView
  